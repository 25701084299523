const yup = require('yup');
const { integerStringSchema } = require('../common');

const showDescription = Object.freeze({
  launchDarklyKey: 'show-description',
  yupSchema: yup.boolean().meta({
    description: 'Enables menu item description',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const showSupportTicketFlow = Object.freeze({
  launchDarklyKey: 'show-support-ticket-flow',
  yupSchema: yup.boolean().meta({
    description:
      'If enabled, use our internal support ticket flow. If disabled, it will point to external nandos help URL.',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const saveOrderAddressesAndRestaurants = Object.freeze({
  launchDarklyKey: 'save-order-addresses-and-restaurants',
  yupSchema: yup.boolean().meta({
    description: "Saves people's addresses in state for the sake of finding a restaurant in Dynamodb",
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const reorder = Object.freeze({
  launchDarklyKey: 'reorder',
  yupSchema: yup.boolean().meta({
    description: 'Allows a user to re-order a past order from your profile',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const removeFromCart = Object.freeze({
  launchDarklyKey: 'remove-from-cart',
  yupSchema: yup.boolean().meta({
    description: 'Enables all remove from cart features (clear order, subcart remove, trash can icon button)',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const payWithPaypal = Object.freeze({
  launchDarklyKey: 'pay-with-paypal',
  yupSchema: yup.boolean().meta({
    description: 'Enables paying with PayPal when available',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const payWithGooglePay = Object.freeze({
  launchDarklyKey: 'pay-with-google-pay',
  yupSchema: yup.boolean().meta({
    description: 'Enables paying with Google Pay when available',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const payWithApplePay = Object.freeze({
  launchDarklyKey: 'pay-with-apple-pay',
  yupSchema: yup.boolean().meta({
    description: 'Enables paying with Apple Pay when available',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const outOfStockProducts = Object.freeze({
  launchDarklyKey: 'out-of-stock-products',
  yupSchema: yup.boolean().meta({
    description: 'Disables the selection of menu items when they are out of stock',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const nutritionalData = Object.freeze({
  launchDarklyKey: 'nutritional-data',
  yupSchema: yup.boolean().meta({
    description: 'Likely to be enabled later, NZ',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const mixedBasting = Object.freeze({
  launchDarklyKey: 'mixed-basting',
  yupSchema: yup.boolean().meta({
    description: 'Allows the selection multiple bastings on a product',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const menuCategoryDescriptions = Object.freeze({
  launchDarklyKey: 'menu-category-descriptions',
  yupSchema: yup.boolean().meta({
    description: 'Show the description for each menu category',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const enableLiveperson = Object.freeze({
  launchDarklyKey: 'enable-liveperson',
  yupSchema: yup.boolean().meta({
    description: 'Enables the live person chat bot. (not currently enabled in mobile)',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const tabOrderingRestaurantWhitelist = Object.freeze({
  launchDarklyKey: 'tab-ordering-restaurant-whitelist',
  yupSchema: yup.object({ restaurantIds: yup.array(integerStringSchema) }).meta({
    description: 'Enable Tab Ordering by restaurant ID',
  }),
  variants: [
    {
      description: 'Disabled',
      value: {
        restaurantIds: [],
      },
    },
  ],
});

const recommendationsEngineRestaurantWhitelist = Object.freeze({
  launchDarklyKey: 'recommendations-engine-restaurant-whitelist',
  yupSchema: yup
    .object({
      restaurants: yup.array(integerStringSchema),
    })
    .meta({
      description: 'Enables recommendation engine for restaurants by ID (wont be required once available everywhere)',
    }),
  variants: [
    {
      description: 'off',
      value: {},
    },
  ],
});

const halalAlert = Object.freeze({
  launchDarklyKey: 'halal-alert',
  yupSchema: yup
    .object({
      restaurantIds: yup.array(integerStringSchema),
    })
    .meta({
      description: 'Shows the halal alert for restaurants as definied in the settings of the feature flag',
    }),
  variants: [
    {
      description: 'off',
      value: {
        restaurantIds: [],
      },
    },
  ],
});

const alcoholWithFoodWa = Object.freeze({
  launchDarklyKey: 'alcohol-with-food-wa',
  yupSchema: yup
    .object({
      excludedCategories: yup.array(yup.string()),
      excludedPLUs: yup.array(integerStringSchema),
    })
    .meta({
      description: 'Used to define what categories or PLUs do not qualify an alcohol purchase in WA for mobile',
    }),
  variants: [
    {
      description: 'disabled',
      value: {
        excludedCategories: [],
        excludedPLUs: [],
      },
    },
  ],
});

const digitalWallet = Object.freeze({
  launchDarklyKey: 'digital-wallet',
  yupSchema: yup
    .boolean()
    .meta({
      description: 'Controls whether to display digital wallet item in the web page drawer. Web and Mobile.',
    })
    .required(),
  variants: [{ description: 'default value', value: true }],
});

module.exports = {
  webAndmobileFlagsData: Object.freeze({
    [digitalWallet.launchDarklyKey]: digitalWallet,
    [alcoholWithFoodWa.launchDarklyKey]: alcoholWithFoodWa,
    [halalAlert.launchDarklyKey]: halalAlert,
    [recommendationsEngineRestaurantWhitelist.launchDarklyKey]: recommendationsEngineRestaurantWhitelist,
    [tabOrderingRestaurantWhitelist.launchDarklyKey]: tabOrderingRestaurantWhitelist,
    [enableLiveperson.launchDarklyKey]: enableLiveperson,
    [menuCategoryDescriptions.launchDarklyKey]: menuCategoryDescriptions,
    [mixedBasting.launchDarklyKey]: mixedBasting,
    [nutritionalData.launchDarklyKey]: nutritionalData,
    [outOfStockProducts.launchDarklyKey]: outOfStockProducts,
    [payWithApplePay.launchDarklyKey]: payWithApplePay,
    [payWithGooglePay.launchDarklyKey]: payWithGooglePay,
    [payWithPaypal.launchDarklyKey]: payWithPaypal,
    [removeFromCart.launchDarklyKey]: removeFromCart,
    [reorder.launchDarklyKey]: reorder,
    [saveOrderAddressesAndRestaurants.launchDarklyKey]: saveOrderAddressesAndRestaurants,
    [showDescription.launchDarklyKey]: showDescription,
    [showSupportTicketFlow.launchDarklyKey]: showSupportTicketFlow,
  }),
};
